















































import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialCardComponent from '@/shared/materialCard.component.vue';

@Component({
  components: {
    MaterialCardComponent,
  },
})
export default class StatusCardComponent extends Vue {
  @Prop() subText!: string;

  @Prop() icon!: string;

  @Prop() subTextColor!: boolean;

  @Prop() subIcon!: string;

  @Prop() subIconColor!: string;

  @Prop() title!: string;

  @Prop() smallValue!: string;

  @Prop() value!: string;

  @Prop() route!: string;

  @Prop() tip!: string;
}
