






































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MaterialCardComponent extends Vue {
  @Prop({ default: '' }) avatar!: string;

  @Prop({ default: 'white' }) color!: string;

  @Prop() icon!: string;

  @Prop({ default: false }) image!: boolean;

  @Prop({ default: false }) statusCard!: boolean;

  @Prop({ default: '' }) text!: string;

  @Prop({ default: '' }) title!: string;

  @Prop() route!: string;

  get classes() {
    return {
      'v-card--material--has-heading': this.hasHeading,
      'status-card': this.isStatusCard,
    };
  }

  get hasHeading() {
    return Boolean(this.$slots.heading || this.title || this.icon);
  }

  get isStatusCard() {
    return Boolean(this.statusCard);
  }

  get hasAltHeading() {
    return Boolean(this.$slots.heading || (this.title && this.icon));
  }

  goToRoute() {
    if (this.route) {
      this.$router.push(this.route);
    }
  }
}
