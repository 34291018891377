









/* eslint-disable*/

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ChartData } from "@/types/drilldownChart.type";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
@Component
export default class DashboardComponent extends Vue {
  zoomOutButton!: am4core.ZoomOutButton;

  currentlySelected!: am4core.Slice;

  chart!: am4plugins_sunburst.Sunburst;

  level0SeriesTemplate!: am4plugins_sunburst.SunburstSeries;

  @Prop() data!: ChartData[];

  @Prop() title!: string;

  $refs!: {
    chartdiv: HTMLElement;
  };

  @Watch("lang", { deep: true })
  onPropertyChanged(value: string, oldValue: string) {
    this.drawChart();
  }

  servertranslate = this.$options.filters!.servertranslate;

  // Themes end

  // create chart
  mounted() {
    if (this.data.length) {
      this.drawChart();
    }
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  drawChart() {
    const chart = am4core.create(
      this.$refs.chartdiv,
      am4plugins_sunburst.Sunburst
    );
    chart.padding(0, 0, 0, 0);
    chart.radius = am4core.percent(98);
    chart.data = this.data;
    chart.colors.step = 2;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(20);
    chart.rtl = this.lang === "ar";
    // add title
    if (this.title) {
      const title = chart.titles.create();
      title.text = `${this.$t(this.title)}`;
      title.fontSize = 15;
      title.marginBottom = 5;
      title.marginTop = 15;
      title.fontWeight = "bold";
    }

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";
    this.chart = chart;
    this.createSeriesTemplate();
  }

  createSeriesTemplate() {
    this.level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();

    this.chart.seriesTemplates.setKey("0", this.level0SeriesTemplate);

    // this makes labels to be hidden if they don't fit
    this.level0SeriesTemplate.labels.template.truncate = true;
    this.level0SeriesTemplate.labels.template.hideOversized = true;
    this.level0SeriesTemplate.labels.template.adapter.add(
      "text",
      (radius, target) =>
        `${this.servertranslate(
          target.dataItem.category
        )}: ({value.percent.formatNumber('#.0')})%`
    );
    this.level0SeriesTemplate.tooltip!.label.adapter.add(
      "text",
      (text, target: any) =>
        `${this.servertranslate(target.dataItem.category)}: {value}`
    );
    this.level0SeriesTemplate.showOnInit = false;
    this.level0SeriesTemplate.usePercentHack = false;

    this.level0SeriesTemplate.radius = am4core.percent(100);
    this.level0SeriesTemplate.innerRadius = am4core.percent(0);

    const selectedState = this.level0SeriesTemplate.states.create("selected");
    selectedState.properties.opacity = 0.7;
    this.level0SeriesTemplate.defaultState.properties.radius = am4core.percent(
      100
    );

    this.addSeriesListeners();
    this.createHigherLevelSeries();
  }

  overListener() {
    this.level0SeriesTemplate.slices.template.events.on(
      "over",
      (event: any) => {
        if (event.target.dataItem.sunburstDataItem.children) {
          event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        }
      }
    );
  }

  onHitListener() {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    this.level0SeriesTemplate.slices.template.events.on("hit", (event: any) => {
      this.zoomOutButton.show();
      const hitSlice = event.target;

      if (hitSlice.dataItem.sunburstDataItem.children) {
        const series = event.target.dataItem.component;

        if (!series.dummyData) {
          series.tooltip.disabled = true;
          hitSlice.dataItem.label.disabled = true;

          this.currentlySelected = hitSlice;
          series.dummyData = true;
          series.setState("selected");
          hitSlice.dataItem.sunburstDataItem.series.show();
          series.slices.each((slice) => {
            if (slice !== event.target) {
              slice.dataItem.hide();
            }
          });
        } else {
          this.drillUp(hitSlice, event);
        }
      }
    });
  }

  rotationListener() {
    this.level0SeriesTemplate.labels.template.adapter.add(
      "rotation",
      (rotation, oldTarget) => {
        const target = oldTarget;
        target.maxWidth =
          target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(
          ((target.dataItem.slice.arc *
            (target.dataItem.slice.innerRadius +
              target.dataItem.slice.radius)) /
            2) *
            am4core.math.RADIANS
        );
        return rotation;
      }
    );
  }

  createZoomOutButton() {
    this.zoomOutButton = this.chart.seriesContainer.createChild(
      am4core.ZoomOutButton
    );
    this.zoomOutButton.visible = false;
    this.zoomOutButton.horizontalCenter = "middle";
    this.zoomOutButton.verticalCenter = "middle";
    this.zoomOutButton.events.on("hit", (event) => {
      this.drillUp(this.currentlySelected, event);
    });
  }

  createHigherLevelSeries() {
    const level1SeriesTemplate = this.level0SeriesTemplate.clone();
    level1SeriesTemplate.hidden = true;
    level1SeriesTemplate.innerRadius = am4core.percent(10);
    this.chart.seriesTemplates.setKey("1", level1SeriesTemplate);
    level1SeriesTemplate.fillOpacity = 0.75;

    const level2SeriesTemplate = this.level0SeriesTemplate.clone();
    level2SeriesTemplate.hidden = true;
    level2SeriesTemplate.innerRadius = am4core.percent(20);
    this.chart.seriesTemplates.setKey("2", level2SeriesTemplate);
    this.createZoomOutButton();
  }

  addSeriesListeners() {
    this.overListener();
    this.onHitListener();
    this.rotationListener();
  }

  drillUp(slice, event) {
    this.collapse(slice);
    const series = slice.dataItem.component;
    series.tooltip.disabled = false;
    series.dummyData = false;
    series.setState("default");

    series.slices.each((serieSlice) => {
      if (serieSlice !== event.target) {
        serieSlice.dataItem.show();
      }
    });

    if (series.parentDataItem.seriesDataItem) {
      this.currentlySelected = series.parentDataItem.seriesDataItem.slice;
    } else {
      this.zoomOutButton.hide();
    }
  }

  collapse(sliceData) {
    const slice = sliceData;
    slice.dataItem.label.bent = false;
    slice.dataItem.label.radius = 10;

    if (slice.dataItem.sunburstDataItem.children) {
      slice.dataItem.sunburstDataItem.children.each((child) => {
        child.seriesDataItem.component.setState("hidden");
        this.collapse(child.seriesDataItem.slice);
      });
    }
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
