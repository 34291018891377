

































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StatusCardComponent from '@/shared/statusCard.component.vue';
import BarChartComponent from '@/shared/barChart.component.vue';
import DrilldownChartComponent from '@/shared/drillDownChart.component.vue';
import HomeProvider from '@/providers/home.provider';
import DashboardProvider from '@/providers/dashboard.provider';
import {
  DashboardCoursesResponse,
  DashboardSubjectsResponse,
  DashboardQuestionsResponse,
  DashboardExamsResponse,
  SyncCoursesResponse,
} from '@/types/dashboard.type';
import servertranslate from '@/filters/server-translate';
import { ProfileData } from '@/types/general.type';
import Toaster from '@/mixins/toaster.mixin';

@Component({
  components: {
    StatusCardComponent,
    BarChartComponent,
    DrilldownChartComponent,
  },
})
export default class DashboardComponent extends mixins(Toaster) {
  draw = false;

  homeProvider = new HomeProvider();

  dashboardProvider = new DashboardProvider();

  dashboardCoursesResponse!: DashboardCoursesResponse;

  dashboardSubjectsResponse!: DashboardSubjectsResponse;

  dashboardQuestionsResponse!: DashboardQuestionsResponse;

  dashboardExamsResponse!: DashboardExamsResponse;

  UserData: ProfileData = {
    name: { en: '', ar: '' },
    nationalId: '',
    email: '',
    foundation: '',
    endDate: '',
    userName: '',
  };

  labelMaxWidth = 90;

  labelBreak = 1500;

  questionsStats: number | undefined = 0;

  PercentCourses=[];

  syncLoading = false;

  get courses() {
    return this.$t('courses');
  }

  get coursesTip() {
    return this.$t('coursesTip');
  }

  get subjects() {
    return this.$t('subjects');
  }

  get subjectsTip() {
    return this.$t('subjectsTip');
  }

  get questions() {
    return this.$t('questions');
  }

  get questionsTip() {
    return `${this.$t('questionsTip')}  ${this.servertranslate(this.UserData.name)}`;
  }

  get exams() {
    return this.$t('exams');
  }

  get examsTip() {
    return `${this.$t('examsTip')}  ${this.servertranslate(this.UserData.name)}`;
  }

  get questionsXAxisLabel() {
    return this.$t('questionsStatus');
  }

  get questionsYAxisLabel() {
    return this.$t('questionsCount');
  }

  get userRightsXAxisLabel() {
    return this.$t('questionsStatus');
  }

  get userRightsYAxisLabel() {
    return this.$t('questionsCount');
  }

  get UserRightsChart() {
    return this.$t('chartRights');
  }

  get UserchartPrograms() {
    return this.$t('chartPrograms');
  }

  get canSyncCourses() {
    return this.$store.state.auth.canSyncCourses;
  }

  servertranslate = servertranslate;

  dataFields = {
    category: 'status',
    value: 'value',
  };

  UserRightsFields = {
    category: 'name',
    value: 'value',
  };

  get questionsData() {
    return this.dashboardQuestionsResponse.questions?.map((c) => ({
      ...c,
      status: this.servertranslate(c.status),
    }));
  }

  get UserRightsData() {
    return this.dashboardCoursesResponse.dashboardCourses?.map((c) => ({
      ...c,
      name: this.servertranslate(c.name),
    }));
  }

  labelBulletObject = {
    dy: -10,
  };

  async syncCourses(type: string) {
    try {
      this.syncLoading = true;
      const result: SyncCoursesResponse = await this.dashboardProvider.syncCourses(type);
      this.successToaster(result.message);
      if (result.rightsCount && result.rightsCount > 0) {
        setTimeout(() => {
          this.$router.go(0);
        }, 2000);
      }
      this.syncLoading = false;
    } catch (error) {
      this.errorToaster(error);
      this.syncLoading = false;
    }
  }

  async mounted() {
    const { activeStageId } = this.$store.state.app;
    const [userHomeData, dashboardCourses, dashboardSubjects, dashboardQuestions, dashboardExams] = await Promise.all([
      this.homeProvider.getProfileData(this.$store.state.auth.username),
      this.dashboardProvider.getDashboardCourses(activeStageId),
      this.dashboardProvider.getDashboardSubjects(activeStageId),
      this.dashboardProvider.getDashboardQuestions(activeStageId),
      this.dashboardProvider.getDashboardExams(activeStageId),
    ]);
    this.UserData = userHomeData;
    this.dashboardCoursesResponse = dashboardCourses;
    this.dashboardSubjectsResponse = dashboardSubjects;
    this.dashboardQuestionsResponse = dashboardQuestions;
    this.dashboardExamsResponse = dashboardExams;

    /* eslint-disable no-param-reassign */
    this.questionsStats = this.dashboardQuestionsResponse.questions?.reduce(
      (acc, record) => {
        if (record.value && record.quesCount) {
          acc += record.value;
        }
        return acc;
      },
      0
    );
    /* eslint-enable no-param-reassign */

    this.draw = true;
  }
}
